import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { Checkbox } from "@atlaskit/checkbox";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import "@zendeskgarden/css-buttons";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { restaurantID } from "../restaurantInfo";
import firebase from "../firebaseInit";
import update from "immutability-helper";

import "../styles/index.css";

import "firebase/firestore";
import "firebase/auth";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2.22,
    paddingBottom: theme.spacing.unit * 2.22,
  },
  card: {
    width: 555,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 2,
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signedIn: null,
      availability: {},
      menu: null,
    };
  }

  componentDidMount() {
    const firestore = firebase.app().firestore();
    this.restaurantRef = firestore.collection("restaurants").doc(restaurantID);
    this.checkIfSignedIn();
  }

  listenToAvailability = () => {
    this.restaurantRef.get().then(doc => {
      const { menu, config, eggRollSideAvailable } = doc.data();
      const { availability } = config;

      this.setState({
        pickup: availability.pickup,
        delivery: availability.delivery,
        restaurant: availability.restaurant,
        closedMessage: availability.closedMessage,
        deliveryWaitMinutes: availability.deliveryWaitMinutes,
        pickupWaitMinutes: availability.pickupWaitMinutes,
        sundayDelivery: availability.sundayDelivery,
        mondayDelivery: availability.mondayDelivery,
        menu,
        eggRollSideAvailable,
        morningDelivery: availability.morningDelivery,
        redirectToNewWebsite: availability.redirectToNewWebsite,
      });
    });
  };

  checkIfSignedIn = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState(
          {
            signedIn: true,
          },
          this.listenToAvailability
        );
      } else {
        this.setState({
          ...this.state,
          signedIn: false,
        });
      }
    });
  };

  updateField = name => event => {
    const { value } = event.target;
    this.setState({ [name]: value.replace(/\s/g, "") });
  };

  signIn = () => {
    const { password, email, isChecked } = this.state;
    if (isChecked === true) {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() =>
          firebase.auth().signInWithEmailAndPassword(email, password)
        );
    } else {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() =>
          firebase.auth().signInWithEmailAndPassword(email, password)
        );
    }
  };

  handleCheck = () => {
    const { isChecked } = this.state;
    const newCheckedState = !isChecked;
    this.setState({
      isChecked: newCheckedState,
    });
  };

  handleBooleanChange = (event, appendConfig = true) => {
    const propToUpdate = appendConfig
      ? `config.availability.${event.target.name}`
      : event.target.name;
    this.restaurantRef.update({
      [propToUpdate]: event.target.checked,
    });
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleEggRoll = event => {
    this.handleBooleanChange(event, false);
  };

  handleStringChange = event => {
    const { value, name } = event.target;
    const propToUpdate = `config.availability.${name}`;
    this.restaurantRef.update({
      [propToUpdate]: value,
    });
    this.setState({
      [name]: value,
    });
  };

  handleMenuUpdate = (category, idx, propName, val) => {
    const { menu } = this.state;
    if (typeof val === "boolean") {
      const newMenu = update(menu, {
        [category]: {
          [idx]: {
            [propName]: {
              $set: val,
            },
          },
        },
      });
      this.setState({ menu: newMenu });
      this.restaurantRef.update({
        menu: newMenu,
      });
    } else {
      const lastChar = val[val.length - 1];
      const empty = val.length === 0;
      const newMenu = update(menu, {
        [category]: {
          [idx]: {
            [propName]: {
              $set: lastChar === "." || empty ? val : parseFloat(val),
            },
          },
        },
      });
      this.setState({ menu: newMenu });
      if (/^\d+$/.test(lastChar) && !empty) {
        this.restaurantRef.update({
          menu: newMenu,
        });
      }
    }
  };

  render() {
    const { classes } = this.props;
    const {
      email,
      password,
      isChecked,
      signedIn,
      pickup,
      delivery,
      restaurant,
      closedMessage,
      deliveryWaitMinutes,
      pickupWaitMinutes,
      menu,
      sundayDelivery,
      mondayDelivery,
      eggRollSideAvailable,
      morningDelivery,
      redirectToNewWebsite,
    } = this.state;

    return (
      <div>
        {signedIn === false && (
          <div>
            <Paper
              className={classes.root}
              style={{ display: "table", margin: "0 auto", maxWidth: "88%" }}
            >
              <h2>Sign in to your FoodWeb account</h2>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Email"
                style={{ marginBottom: "0.5em" }}
                value={email}
                onChange={this.updateField("email")}
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Password"
                type="password"
                value={password}
                onChange={this.updateField("password")}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Checkbox
                  isChecked={isChecked}
                  onChange={this.handleCheck}
                  value="Stay signed in"
                  label="Stay signed in"
                  name="checkbox-basic"
                  style={{ transform: "translateX(-3.33%)" }}
                />
              </div>
              <br />
              <br />
              <button
                className="c-btn c-btn--primary c-btn--full c-btn--danger"
                style={{ fontSize: "0.88em", fontWeight: 500 }}
                type="button"
                onClick={this.signIn}
              >
                Sign In
              </button>
            </Paper>
          </div>
        )}
        {signedIn === true && (
          <div
            style={{
              backgroundColor: "rgb(252,252,252)",
              display: "flex",
              flexDirection: "column",
              paddingLeft: "1em",
              paddingRight: "1em",
              paddingBottom: "2em",
            }}
          >
            <h1>Restaurant Settings</h1>
            <FormGroup>
              {redirectToNewWebsite !== undefined && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={redirectToNewWebsite}
                      onChange={e => this.handleBooleanChange(e, true)}
                      name="redirectToNewWebsite"
                    />
                  }
                  label="Redirect to New Website"
                />
              )}
              {eggRollSideAvailable !== undefined && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={eggRollSideAvailable}
                      onChange={this.handleEggRoll}
                      name="eggRollSideAvailable"
                    />
                  }
                  label="Egg Roll Side"
                />
              )}
              {pickup !== undefined && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={pickup}
                      onChange={e => this.handleBooleanChange(e, true)}
                      name="pickup"
                    />
                  }
                  label="Pickup"
                />
              )}
              {pickupWaitMinutes !== undefined && (
                <TextField
                  label="Pickup Wait Minutes"
                  name="pickupWaitMinutes"
                  multiline
                  value={pickupWaitMinutes}
                  onChange={this.handleStringChange}
                />
              )}
              {delivery !== undefined && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={delivery}
                      onChange={e => this.handleBooleanChange(e, true)}
                      name="delivery"
                    />
                  }
                  label="Delivery"
                />
              )}
              {sundayDelivery !== undefined && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={sundayDelivery}
                      onChange={e => this.handleBooleanChange(e, true)}
                      name="sundayDelivery"
                    />
                  }
                  label="Sunday Delivery"
                />
              )}
              {mondayDelivery !== undefined && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={mondayDelivery}
                      onChange={e => this.handleBooleanChange(e, true)}
                      name="mondayDelivery"
                    />
                  }
                  label="Monday Delivery"
                />
              )}
              {morningDelivery !== undefined && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={morningDelivery}
                      onChange={e => this.handleBooleanChange(e, true)}
                      name="morningDelivery"
                    />
                  }
                  label="Morning Delivery"
                />
              )}
              {deliveryWaitMinutes !== undefined && (
                <TextField
                  label="Delivery Wait Minutes"
                  name="deliveryWaitMinutes"
                  multiline
                  value={deliveryWaitMinutes}
                  onChange={this.handleStringChange}
                />
              )}
              {restaurant !== undefined && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={restaurant}
                      onChange={e => this.handleBooleanChange(e, true)}
                      name="restaurant"
                    />
                  }
                  label="Restaurant"
                />
              )}
              {closedMessage !== undefined && (
                <TextField
                  label="Closed Message"
                  name="closedMessage"
                  multiline
                  value={closedMessage}
                  onChange={this.handleStringChange}
                />
              )}
            </FormGroup>
            {menu !== null &&
              Object.keys(menu).map(key => {
                const menuCategory = menu[key];
                return (
                  <ExpansionPanel
                    key={key}
                    CollapseProps={{ unmountOnExit: true }}
                    style={{
                      backgroundColor: "#A0A0A0",
                      width: "100%",
                      margin: "auto",
                      marginTop: 24,
                    }}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{ color: "#080808", transform: "scale(1.22)" }}
                        />
                      }
                    >
                      <Typography
                        variant="h6"
                        style={{ fontFamily: "IBM Plex Sans" }}
                      >
                        {key}
                      </Typography>
                    </ExpansionPanelSummary>
                    <div style={{ backgroundColor: "#D8D8D8" }}>
                      <List style={{ width: "96%", margin: "auto" }}>
                        {menuCategory.map((item, idx) => {
                          if (
                            item.LargePrice ||
                            item.SmallPrice ||
                            item.LargePrice === "" ||
                            item.SmallPrice === ""
                          ) {
                            return (
                              <div key={item.Name + key}>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <span className="regularSpan">
                                        {item.Name}
                                      </span>
                                    }
                                  />
                                </ListItem>
                                {(item.SmallPrice ||
                                  item.SmallPrice === "") && (
                                  <ListItem>
                                    <ListItemText
                                      primary={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          - Pint:{" "}
                                          <TextField
                                            onChange={event =>
                                              this.handleMenuUpdate(
                                                key,
                                                idx,
                                                "SmallPrice",
                                                event.target.value
                                              )
                                            }
                                            style={{ marginLeft: 8 }}
                                            value={item.SmallPrice.toString()}
                                          />
                                        </div>
                                      }
                                    />
                                  </ListItem>
                                )}
                                <ListItem divider>
                                  <ListItemText
                                    primary={
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        - Quart:{" "}
                                        <TextField
                                          style={{ marginLeft: 8 }}
                                          value={item.LargePrice.toString()}
                                          onChange={event =>
                                            this.handleMenuUpdate(
                                              key,
                                              idx,
                                              "LargePrice",
                                              event.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          item.enabled === null ||
                                          item.enabled === undefined
                                            ? true
                                            : item.enabled
                                        }
                                        onChange={event =>
                                          this.handleMenuUpdate(
                                            key,
                                            idx,
                                            "enabled",
                                            event.target.checked
                                          )
                                        }
                                        name="available"
                                      />
                                    }
                                  />
                                </ListItem>
                              </div>
                            );
                          }
                          return (
                            <div key={item.Name + key}>
                              <ListItem divider>
                                <ListItemText
                                  primary={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item.Name}
                                      <TextField
                                        style={{ marginLeft: 8 }}
                                        value={item.Price.toString()}
                                        onChange={event =>
                                          this.handleMenuUpdate(
                                            key,
                                            idx,
                                            "Price",
                                            event.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        item.enabled === null ||
                                        item.enabled === undefined
                                          ? true
                                          : item.enabled
                                      }
                                      onChange={event =>
                                        this.handleMenuUpdate(
                                          key,
                                          idx,
                                          "enabled",
                                          event.target.checked
                                        )
                                      }
                                      name="available"
                                    />
                                  }
                                />
                              </ListItem>
                            </div>
                          );
                        })}
                      </List>
                    </div>
                  </ExpansionPanel>
                );
              })}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(App);
